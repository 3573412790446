@import 'assets/stylesheets/base-styles.scss';
@import 'assets/stylesheets/colors.scss';

.whiteOutlinedTextField {
  & input {
    color: white;
  }
  & label {
    color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    & .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
  }
  & .MuiOutlinedInput-root.Mui-error {
    & .MuiOutlinedInput-notchedOutline {
      border-color: $secondary-brand;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $secondary-brand;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $secondary-brand;
    }
  }
  & .Mui-error {
    color: $secondary-brand !important;
  }
  & .MuiInput-underline.Mui-error:after {
    border-color: $secondary-brand !important;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: $secondary-brand !important;
  }
}

.primaryOutlinedTextField {
  & label.Mui-focused {
    color: $primary-brand;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-brand;
    }
    &.Mui-focused .MuiSvgIcon-root {
      color: $primary-brand;
    }
  }
}

.primaryOutlinedSelect {
  & span {
    color: $primary-brand;
  }
  &.Mui-focused fieldset {
    border-color: $primary-brand !important;
  }
  &.Mui-focused .MuiSelect-icon {
    color: $primary-brand;
  }
}
