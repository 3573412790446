@import '../../assets/stylesheets/colors.scss';

.icon {
  color: $primary-brand;
}

.buttonOverlay {
  width: 47px;
  border-radius: 100px;
}

.title {
  font-size: 36px !important;
  font-weight: 700 !important;
}

.paddingForm {
  padding: 20px 20px 30px 20px;
}

.dialogPaddingTop {
  padding-top: 20px !important;
}
