@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.label{
  color: black !important;
  font-size: 18px;
}

.subtitle{
  color: black !important;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
}

.spacer {
  height: 15px;
}

.bigSpacer {
  height: 40px;
}

.component{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-overflow: wrap;
  overflow: hidden;

  @include xs {
    flex-direction: column;
  }
}

.column {
  width: 48%;

  @include xs {
    width: 100%;
  }
}