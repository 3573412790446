@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.label{
  color: black !important;
  font-size: 18px;
}

.title {
  color: black !important;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

.subtitle{
  color: black !important;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
}

.spacer {
  height: 15px;
}

.horizontalSpacer {
  width: 15px;
}

.verticalMobileSpacer {
  @include xs {
    height: 15px;
  }
}

.bigSpacer {
  height: 40px;
}

.component{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-overflow: wrap;
  overflow: hidden;

  @include xs {
    flex-direction: column;
  }
}

.personName {
  color: black !important;
  font-weight: bold;
  font-size: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.personBithDate {
  color: black !important;
  font-size: 20px;
}

.marginInfo {
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}

.marginInfo > * {
  margin-right: 2%;
}

.containerButton {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  height: 135px;

  @include sm {
    flex-direction: column;
    height: 125px;
  }

  @include md {
    flex-direction: column;
    height: 125px;
  }

  @include lg {
    flex-direction: row;
    height: 35px;
  }
}

.containerButton > button {
  @include xs {
    margin-bottom: 10px;
  }
  @include sm {
    margin-bottom: 10px;
  }
  @include md {
    margin-bottom: 10px;
  }
  @include lg {
    margin-bottom: 0px;
  }
}

.containerButtonAddEvent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 15px;

  @include xs {
    flex-direction: column;
    margin-bottom: 60px;
    margin-right: 0px;
  }
}

.nameAndButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include xs {
    flex-direction: column;
  }
  @include sm {
    flex-direction: column;
    margin-bottom:15px;
  }
}

.importedFlag {
  margin-left: 10px;
}

.spacer {
  width: 15px;
}

.paper {
  padding: 6px 16px;
}

.verticalTimeLineColor::before {
  background:#C4C4C4;
}

.verticalTimeLineColor > div {
  margin: 0 0 10px 0;
}

.verticalTimelineElementDate {
  & > div {
    box-shadow:0 0px 0 #DDDDDD;
  }

  & > span {
    background-color: $primary-brand;
    color: white;
  }
}

.fullWidth {
  width: 100%;
}

.marginlastThree {
  margin-top: -10px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.personDetailComment {
  color: black !important;
  margin-bottom: 10px;
  font-size: 15px;
}
