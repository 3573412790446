@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.container {
  height: 100vh;
  padding: 0px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: $secondary-brand;

  @include xs {
    padding: 0px 10px;
  }
}

.container > img {
  width: 250px;
  @include xs {
    width: 200px;
  }
  margin-bottom: 50px;
}

.textContainer {
  color: $primary-brand;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}

.textContainer > h1 {
  margin-bottom: 25px;
}

.textContainer > p {
  font-size: 25px;
  margin-bottom: 15px;
}

.redirectContainer {
  display: flex;
  flex-direction: column;
}

.redirectContainer > p {
  font-size: 20px;
  margin-bottom: 15px;
}
