@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.spacer {
  margin-top: 25px;
}

.confirmCancelButtonsContainer {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  
  & > span {
    margin: 0px 25px;
    width: 100px;

    @include xs {
      font-size: 13px;
    }
  }
}

.fullWidth {
  width: 100%;
}
