@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include xs {
    display: flex;
    flex-direction: column;
  }
}

.titleAndEnrolledPeople {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  @include xl {
    flex-direction: row;
    justify-content: space-between;
  }
}

.titleAndEnrolledPeople > div {
  align-self: center;

  @include xl {
    align-self: inherit;
  }
}

.spacer {
  width: 20px;

  @include xs {
    height: 10px;
  }
}

.heightSpacer{
  height: 35px;
}

.dates {
  display: flex;
  flex-direction: row;
  margin-right: 35px;
  align-items: center;

  @include xs {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
}

.buttons {
  margin-top: 30px;
  margin-right: 50px; 
}

.contentContainer {
  display: flex;
  flex-direction: column;
  @include xl {
    flex-direction: row;
  }
}

.institutionPickContainer {
  margin-right: 0px;
  margin-bottom: 20px;
  align-self: center;
  justify-self: center;

  @include xl {
    min-width: 260px;
    margin-right: 20px;
    align-self: inherit;
    justify-self: inherit;
  }
}

.chartsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;

  @include xl {
    width: calc(100% - 260px);
  }
}

.charts {
  width: 100%;

  @include xl {
    width: calc(100% - 150px);
  }
}

