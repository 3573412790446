.label {
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.value{
  color: black;
  font-size: 18px;
}
