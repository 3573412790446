@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spacer {
  width: 20px;
}

.charts {
  width: calc(90% - 140px);
  @include sm {
    width: 95%;
  }
  @include xs {
    width: 95%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;  
  font-size: 25px;
}

.container {
  display: flex;
  align-items: center;

  padding: 30px 10px;
  height: 30px;

  border: 2px solid $terciary-brand;
  border-radius: 8px;

  box-sizing: border-box;
}
