@import '../../assets/stylesheets/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid darkgray;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-right: 12px;
}

.children {
  display: flex;
  flex-direction: column;
  padding: 18px;
  cursor: pointer;
}

.icon {
  color: $primary-brand;
}

.children b {
  color: $primary-brand;
  font-weight: 600;
}

.timeDifference {
  margin-top: 20px;
  font-size: small;
  color: darkgray;
}
