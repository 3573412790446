@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.spacer {
  height: 16px;
  width: 16px;
}

.subtitle {
  color: black !important;
  font-weight: bold;
  font-size: 26px;
}

.label{
  color: black !important;
}

.column {
  width: 30%;
  margin-right: 3%;

  @include xs {
    width: 100%;
  }
}

.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

  @include xs {
    flex-direction: column;
  }
}

.additionalTextfield {
  width: auto;
}

.additionalInformation {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include xs {
    flex-direction: column;
  }
}

.actionsContainer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
