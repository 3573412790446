/*
  Add colors as variables here like this:

  $color-name: #000000;

  Please refrain from adding code that generates CSS.
*/

$primary-brand: #586ea0; 
$secondary-brand: #F4D35F;
$terciary-brand: #C4C4C4;
$item-hover-background: #F4F4F4;
