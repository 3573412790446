@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.text {
  display: flex;
  flex-direction: column;
  align-items: center;  
  font-size: 25px;
}

.container {
  display: flex;
  align-items: center;

  padding: 30px 10px;
  height: 30px;

  border: 2px solid $terciary-brand;
  border-radius: 8px;

  box-sizing: border-box;
};
