@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding: 30px 10px;
  height: 300px;

  border: 2px solid $primary-brand;
  border-radius: 8px;

  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  & > p {
    align-self: center;
    margin-bottom: 30px;
    font-size: 18px;
  }
}
