@import "../../assets/stylesheets/colors.scss";

$transition-time: .4s;

.mainHeader {
  height: 176px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .header {
    display: flex;
    height: 100px;
    width: 100%;
    background-color: $primary-brand;
    align-items: center;
    .badge {
      right: 160px;
      top: 30px;
      position: absolute;
    }
    figure {
      width: 140px;
      height: 70px;
      position: absolute;
      right: 5px;
      top: 11px;
      img {
        object-fit: cover;
        width: 95%;
      }
    }
  }
  .mainNavigation {
    height: 46px;
    width: 100%;
    display: block;
    background-color: #d3d3d3;
    .leftMenu {
      float: left;
    }
    .rightMenu {
      float: right;
    }
  }
}



li {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  height: 46px;
  text-align: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 46px;
}

.hamburgerMenu {
  width: 81px;
  height: 70px;
  position: absolute;
  left: 20px;
  top: 17px;
  cursor: pointer;
  .hamburger {
    color: white;
    width: 81px !important;
    height: 70px !important;
  }
}

// Mobile menu CSS

.mobileMenu {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  background-color: #E7E7E7;
  transition: width $transition-time;
  overflow-x: hidden;
  & ul {
    padding-top: 30px;
    li {
      padding-left: 17px;
      padding-right: 17px;
      height: 36px;
      width: 260px;
      overflow: hidden;
      text-align: start;
    }
  }
  & .logout {
    padding-top: 55px;
    padding-left: 17px;
    width: 250px;
    overflow: hidden;
  }
}

.userInfo {
  padding: 30px 0 0 23px;
  color: black;
  h5 {
    font-weight: 600;
  }
  p {
    font-weight: 600;
    font-size: 14px;
  }
}

.mobileMenu.open {
  width: 250px;
}

.mobileMenu.closed {
  width: 0px;
}

.mobileMenuButton {
  font-size: 17px !important;
  font-weight: 600 !important;
  width: 100%;
  justify-content: start !important;
  color: black !important;
  text-transform: none !important;
  & > * {
    color: $primary-brand;
  }
}

.closeMenu {
  width: 0;
  height: 0;
  opacity: 0;
  position: fixed;
  top:0;
  left:0;
  background: rgba(0,0,0,.6);
  background-color: gray;
  transition: opacity $transition-time;
}

.closeMenu.open {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.appLink {
  background-color: inherit;
}

.selectedAppLink {
  background-color: $secondary-brand;
}

.icon {
  color: white;
}

.drawer {
  max-width: 80%;
}
