@import 'breakpoints';
@import 'text-styles';
@import 'colors';

.primaryButton {
  background-color: $primary-brand !important;
  margin-right: 15px;
  color: white;
}

.secondaryButton {
  background-color: $secondary-brand !important;
  margin-right: 15px;
  color: white;
}

.buttonDisabled {
  background-color: lightgrey !important;
  margin-right: 15px;
}
