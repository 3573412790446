@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;

  @include xs {
    display: flex;
    flex-direction: column;
  }
}

.spacer {
  width: 20px;

  @include xs {
    height: 20px;
  }
}

.heightSpacer{
  height: 40px;
}

.dates {
  display: flex;
  flex-direction: row;
  margin-right: 55px;

  @include xs {
    display: flex;
    flex-direction: column;
  }
}

.buttons {
  margin-top: 30px;
  margin-right: 50px; 
}
