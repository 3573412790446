@import '../../assets/stylesheets/colors.scss';

.container {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: $primary-brand;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container h3 {
  color: white;
  margin-bottom: 40px;
}
