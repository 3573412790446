@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.uploadFileContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  & > p {
    margin-left: 15px;
    align-self: center;
  }
  @include xs {
    flex-direction: column;
    & > label {
      align-self: center;
      justify-self: center;
    }
    & > p {
      margin-top: 10px;
      justify-self: center;
    }
  }
}

.spacer {
  margin-top: 25px;
}

.confirmCancelButtonsContainer {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  
  & > span {
    margin: 0px 25px;
    width: 100px;

    @include xs {
      font-size: 13px;
    }
  }
}