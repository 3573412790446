@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include xs {
    flex-direction: column;
    & > button {
      margin-top: 5px;
    }
  }
}

.spacer {
  width: 15px;
}

.label {
  color: $primary-brand !important;
}

.search {
  width: 40%;
  @include xs {
    width: 100%;
  }
}
