@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.nameAndButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button.visible{
  display: flex;
}

button.invisible {
  display: none;
}

.eventCard {
  & > div {
    box-shadow: 2px 0px 2px 2px #DDDDDD;
    padding-bottom:0px;
  }

  & > span {
    background-color: #C4C4C4;
    color: #C4C4C4;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-top: 26px;
  }
} 


.buttonsColumn {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.buttonsRow {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.vertical-timeline-element {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em 0;
}

.marginlastThree {
  margin-top: -12%;
  display: flex;
  flex-direction: column;
}

.link{
  width: fit-content;
  cursor: pointer;
  color:rgb(39, 101, 245);
  text-decoration: underline;
}

.more{
  display: -webkit-box;
}
 
.less{
  display: flex;
}

.value{
  color: black;
  font-size: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.prueba{
  display: none;
}

.selected > div {
  border: 2px solid $secondary-brand;
}
