@import 'variables';
@import 'mixins';

$genericContainerPadding: 10px;
$genericItemMargin: 40px;

.genericContainer {
  box-sizing: content-box;
  margin: 0 auto;
  //This container has a padding top so it doesn't overlapp with the header, that is fixed (always on top of everything)
  padding-top: 170px; 
  position: relative;
}
/*
  It is assumed this container will be used directly inside
  a genericContainer
*/
.genericItemContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.genericItemContainer > * {
  margin-right: $genericItemMargin;
  margin-bottom: $genericItemMargin;
}

.statisticsContainer {
  box-sizing: content-box;
  margin: 0 auto;
  //This container has a padding top so it doesn't overlapp with the header, that is fixed (always on top of everything)
  padding-top: 170px; 
  position: relative;
}
/*
  It is assumed this container will be used directly inside
  a statisticsContainer
*/
.statisticsItemContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.statisticsItemContainer > * {
  margin-right: $genericItemMargin;
  margin-bottom: $genericItemMargin;
}


@include setUpContainerSizes(
  "genericContainer",
  "genericItemContainer",
  $genericContainerPadding,
  $genericItemMargin,
  $items-per-row,
  $breakpoints
);

@include setUpContainerSizes(
  "statisticsContainer",
  "statisticsItemContainer",
  $genericContainerPadding,
  $genericItemMargin,
  $items-per-row-statistics,
  $breakpoints
);
