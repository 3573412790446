.tableContainer {
  margin-top: 20px;
  height: 100%;
}

.table {
  width: 100%;
  height: 100%;
  tr {
    height: 70px;
  }
}

.tableHeader {
  background-color: #d3d3d396;
}
