@import '../../assets/stylesheets/colors.scss';

.container {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.indicator {
  color: $primary-brand !important;
}

.indicatorSecondary {
  color: $secondary-brand !important;
}

