@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.containerHeaderButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include xs{
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.spacer {
  height: 15px;
}

.horizontalSpacer {
  width: 15px;
  @include xs{
    height: 15px;
  }
}

.subtitle {
  color: black !important;
  font-weight: bold;
  font-size: 26px;
}

.label{
  color: black !important;
}

.column {
  width: 30%;
  margin-right: 3%;

  @include xs {
    width: 100%;
  }
}

.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

  @include xs {
    flex-direction: column;
  }
}

.titleHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-height: min-content;
  align-content: flex-start;
  align-items: flex-start;
  @include xs {
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
  }
}
