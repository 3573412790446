@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.buttonsContainer {
  position: relative;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @include xs {
    flex-direction: column;
    & > * {
      width: 100%;
    }
    & > button {
      margin-top: 5px;
    }
  }
}

.spacer {
  width: 15px;
}

.label {
  color: $primary-brand !important;
}
