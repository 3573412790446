@import '../../assets/stylesheets/colors.scss';

.container > p {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;

  margin-bottom: 20px;
}

.icon {
  color: $primary-brand;
}
