@import '../../assets/stylesheets/colors.scss';

.tableRow {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  &:hover {
    background-color: $item-hover-background;
    cursor: pointer;
  }
}

.icon {
  color: $primary-brand;
}

.tableCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textContainer {
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
