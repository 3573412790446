.label {
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.value{
  color: black;
  font-size: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spacer {
  height: 10px;
}

.more{
  display: -webkit-box;
}
 
.less{
  display: flex;
}
 
.link{
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(39, 101, 245);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
 }
 