@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.container {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: $primary-brand;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 500px;
  @include xs{
    width: 300px;
  }

  position: relative;
  z-index: 2;
}

.loginContainer > img {
  width: 250px;
  @include xs{
    width: 200px;
  }
}

.loginContainer > h1 {
  margin: 50px 0px 25px 0px;
  color: white;

  @include xs{
    margin: 20px 0px 20px 0px;
    font-size: 25px;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 500px;
  @include xs{
    width: 300px;
  }

  position: relative;
  z-index: 2;
}

.loginInputs {
  display: flex;
  flex-direction: column;
}

.loginInputsSpaceBetween {
  height: 40px;
}

.loginInputs input {
  width: 400px;
  @include xs{
    width: 250px;
  }
}

.loginButton {
  width: 120px;
  height: 30px;

  margin-top: 20px;

  background-color: white;
  color: $primary-brand;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;

  border-color: white;
  border-width: 1px;
  border-radius: 5px;
}

.loginButton:disabled {
  background-color: lightgrey;
  color: grey;

  cursor: not-allowed;
}
