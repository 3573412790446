@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.containerImage {
  width: 30%;
  background-image: url('../../assets/images/sendFormImage.jpg');
  background-repeat: no-repeat;
  background-size: auto;
  @include xs {
    width: 100%;
    background-image: none;
    background-color: $primary-brand;
  }
}

.containerImage.visible {
  display: block;
}

.containerImage.invisible {
  display: none;
}

.containerTitle {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-family: 'Fira Sans';

  @include xs {
    margin-top: 17%;
  }

  @include sm {
    margin-top: 25%;
  }
}
.containerTitle > h1{
  font-size: 36px;

  @include xs {
    margin-top: -50px;
    font-size: 25px;
  }
  @include sm {
    font-size: 36px;
  }
}
.containerTitle > h2{
  font-size: 28px;

  @include xs {
    font-size: 25px;
  }
  @include sm {
    font-size: 25px;
  }
}

.containerStepbyStep > svg {
  display: block;
  margin: 0 auto;
  @include xs {
    margin-top: -30px;
  }
}

.containerData {
  height: 60%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  @include xs {
    height: 60%;
  }
}

.formInstitutionData {
  height: 70%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;

  @include xs {
    height: 70%;
  }
}

.containerForm {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: center;
  flex-direction: column;
  @include xs {
    display:none;
    width: 100%;
  }
}

.sendForm {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;

  @include xs {
    width: 80%;
  }

  @include sm {
    width: 70%;
  }
}

.titleForm{
  margin-top: -30px;
  justify-content: center;
  display: flex;
  color: $terciary-brand;
}

.titleForm > h1 {
  @include xs {
    text-align: center;
    font-size: 32px;
  }
}

.contentButton {
  justify-content: center;
  display: flex;
  margin-top: 20px;
  @include xs {
    margin-top: 0px;
  }
}

.nextButton {
  width: 143px;
  height: 44px;

  background-color: $primary-brand;
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  border-color: $primary-brand;
  border-width: 1px;
  border-radius: 5px;

  cursor: pointer;

  @include xs {
    width: 105px;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
  }
}

.backButton {
  width: 143px;
  height: 44px;
  margin-right: 20px;

  background-color: $secondary-brand;
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  border-color: $secondary-brand;
  border-width: 1px;
  border-radius: 5px;

  cursor: pointer;

  @include xs {
    width: 90px;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
  }
}

.completeButton {
  width: 143px;
  height: 44px;

  margin-top: 190px;

  visibility: hidden;
  @include xs {
    visibility: visible;
  }

  background-color: #fff;
  color: $primary-brand;
  font-size: 18px;
  font-weight: 600;

  border-color: #fff;
  border-width: 1px;
  border-radius: 5px;
}

.backButton:disabled {
  background-color: $terciary-brand;
  border-color: $terciary-brand;
  color: grey;

  cursor: not-allowed;
}

.containerLogo{
  @include xs {
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    object-fit: contain;
    width: 30%;
    height: 30%;
    margin: 20px 0px 0px 20px;

    @include xs {
      margin: 20px 0px 0px 0px;
    }
  }
}

.containerForm.success {
  background-color: $secondary-brand;
  width: 100%;
}

.containerForm.visible {
  display: flex;
}

.success {
  color: #fff;
}


.lastFigure{
  margin-bottom: 150px;
  margin-left: 90px;
  @include xs {
    width: 80%;
    margin-left: 40px;
  }
  @include sm {
    margin-left: 110px;
  }
}

.lastText > h1 {
  @include xs {
    font-size: 22px;
    justify-content: center;
    display: flex;
    text-align: center;
  }
}

.lastText > a {
    font-size: 16px;
    justify-content: center;
    display: flex;
    text-align: center;
}
