@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';
@import '../../assets/stylesheets/colors.scss';

.numberEnrolledPeople {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 55px;
}
