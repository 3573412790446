@import '../../assets/stylesheets/colors.scss';
@import '../../assets/stylesheets/variables.scss';
@import '../../assets/stylesheets/mixins.scss';

.passwordChangeScreenForm {
  display: flex;
  flex-direction: column;

  width: 500px;
  @include xs{
    width: 300px;
  }

  position: relative;
  z-index: 2;
}

.passwordChangeScreenInputs {
  display: flex;
  flex-direction: column;
}

.passwordChangeSceenInputsSpaceBetween {
  height: 52px;
}

.passwordChangeScreenInputs input {
  width: 400px;
  @include xs{
    width: 250px;
  }
}

.passwordChangeButton {
  display: flex;
  justify-content: center;

  width: 100%;
}
